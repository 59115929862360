import React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import MonitoringIndex from "../../components/solutions/monitoring/MonitoringIndex";

const ogImage = {
  ogImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/monitoring-graphql.png",
};

const canonicalUrl = "https://hasura.io/graphql/monitoring/";

const Monitoring = ({ location }) => (
  <Layout location={location}>
    <Seo
      title="GraphQL Monitoring, Logging and Observability | Hasura"
      description="Monitor your GraphQL API for insights into your API availability, performance & metrics. Export to external services like DataDog, New Relic & Azure Monitor."
      meta={ogImage}
      canonicalLink={canonicalUrl}
    />
    <MonitoringIndex />
  </Layout>
);

export default Monitoring;
