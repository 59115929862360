import React, { Fragment, useRef, useMemo } from "react";

import SubNav from "../common/SubNav";
import TopBanner from "../common/TopBanner";
import CachingWithHasura from "../common/CachingWithHasura";
import Features from "../../database/sqlServer/Features";
import StyledSectionWrapper from "../../shared/StyledSectionWrapper";
import GetStarted from "../common/GetStarted";
import { StyledDesc1 } from "../../shared/StyledTypography";

import tickCircle from "../../homepagenew/images/tick-circle.svg";
import monitor from "../images/monitor.svg";
import error from "../images/error.svg";
import dashboard from "../images/dashboard.svg";
import piechart from "../images/piechart.svg";
import datadog from "../images/datadog.png";
import newrelic from "../images/newrelic.png";
import azuremonitor from "../images/azuremonitor.png";
import monitorSlowQueries from "../images/monitor-slow-queries.png";
import trackingErrors from "../images/tracking-errors.png";
import distributedTracing from "../images/distributed-tracing.png";
import subscriptionMonitoring from "../images/subscription-monitoring.png";

const topBannerState = {
  title: "Monitoring and Observability for all your GraphQL APIs ",
  subTitle: "Gain insights into your API availability and performance",
  btnContent: "Monitor your GraphQL API",
  btnLink: "https://cloud.hasura.io/signup?pg=graphql-monitoring&plcmt=body&cta=monitor-your-graphql-api&tech=default",
  width75: true,
  linkView: {
    title: "View Recording",
    linkText: "Best Practices for API Observability with Hasura",
    linkUrl: "/events/webinar/best-practices-for-api-observability-with-hasura/",
  }
}

const externalServicesBrand = [
  {
    imgSrc: datadog,
    altTxt: "Datadog",
  },
  {
    imgSrc: newrelic,
    altTxt: "New Relic",
  },
  {
    imgSrc: azuremonitor,
    altTxt: "Azure Monitor",
  },
]

const monitoringListState = [
  {
    id: "monitor-slow-queries",
    imgSrc: monitor,
    linkContent: "Monitor for Slow Queries",
    title: "Monitor for Slow Queries",
    desc: <span>
      <ul className="discUl">
        <li className="disc">Find high latency queries from the dashboard</li>
        <li className="disc">Optimise your database through Explain/Analyze</li>
        <li className="disc">Compare query performance over time</li>
      </ul>
    </span>,
    panelImg: monitorSlowQueries,
  },
  {
    id: "tracking-errors",
    imgSrc: error,
    linkContent: "Tracking Errors",
    title: "Tracking Errors",
    desc: <span>
      <ul className="discUl">
        <li className="disc">Check and filter responses with “errors” object</li>
        <li className="disc">Debug in dev mode to pinpoint issues</li>
        <li className="disc">Perform regression testing to prevent errors from going into production</li>
        <li className="disc">Enable higher logging level for debugging</li>
      </ul>
    </span>,
    panelImg: trackingErrors,
  },
  {
    id: "distributed-tracing",
    imgSrc: dashboard,
    linkContent: "Distributed Tracing",
    title: "Distributed Tracing",
    desc: <span>
      <ul className="discUl">
        <li className="disc">Get a unified view of performance of all components in Hasura</li>
        <li className="disc">Trace requests across database, remote schemas, events and action handlers</li>
        <li className="disc">Track execution time per individual request</li>
        <li className="disc">Support for integration of trace information with APM</li>
      </ul>
    </span>,
    panelImg: distributedTracing,
  },
  {
    id: "websocket-monitoring",
    imgSrc: piechart,
    linkContent: "Websocket Monitoring",
    title: "Manage your realtime apps with Websocket Monitoring",
    desc: <span>
      <ul className="discUl">
        <li className="disc">Track number of websocket connections open</li>
        <li className="disc">Visualize average execution time of subscription workers</li>
        <li className="disc">Monitor open and closed states of a websocket connection</li>
      </ul>
    </span>,
    panelImg: subscriptionMonitoring,
  },
]

const MonitoringIndex = () => {
  const observableSystemRef = useRef(null);
  const featuresRef = useRef(null);
  const monitorSlowQueriesRef = useRef(null);
  const trackingErrorsRef = useRef(null);
  const distributedTracingRef = useRef(null);
  const websocketMonitoringRef = useRef(null);

  const sectionRefs = useMemo(
    () => [
      { name: "Observable System", section: "observable-system", ref: observableSystemRef },
      { name: "Features", section: "features", ref: featuresRef },
    ],
    []
  );

  const monitoringSection = {
    "monitor-slow-queries": { section: "monitor-slow-queries", ref: monitorSlowQueriesRef },
    "tracking-errors": { section: "tracking-errors", ref: trackingErrorsRef },
    "distributed-tracing": { section: "distributed-tracing", ref: distributedTracingRef },
    "websocket-monitoring": { section: "websocket-monitoring", ref: websocketMonitoringRef },
  }

  return (
    <>
      <SubNav
        sectionRefs={sectionRefs}
        pageName="Monitoring"
        pagePath="/graphql/monitoring/"
        currentDropDownMenu="solutions"
        subNavBtnLink="https://cloud.hasura.io/signup?pg=graphql-monitoring&plcmt=sub-header&cta=get-started-now&tech=default"
      />
      <TopBanner topBannerState={topBannerState} paddBottom borderBottom/>
      <div id="observable-system" ref={observableSystemRef}>
        <CachingWithHasura
          title="Why do you need an observable system?"
          desc="Without an observable system, it would be difficult to understand what is going wrong with the system unless you are monitoring for known issues. At any point of time, you should be able to ask any arbitrary question about how your application works."
          rightDesc=<span>
            <StyledDesc1 fontWeight="font_600" paddingBottom="pb8">Metrics involved in queries:</StyledDesc1>
            <StyledDesc1>
              <ul>
                <li><img className="leftIcon" src={tickCircle} alt="Tick" />Time of query and query execution time</li>
                <li><img className="leftIcon" src={tickCircle} alt="Tick" />Actual query payload / query hash</li>
                <li><img className="leftIcon" src={tickCircle} alt="Tick" />Response status codes of queries/mutations/subscriptions</li>
                <li><img className="leftIcon" src={tickCircle} alt="Tick" />GraphQL Server Version</li>
                <li><img className="leftIcon" src={tickCircle} alt="Tick" />IP Address from which the query originated</li>
              </ul>
            </StyledDesc1>
          </span>
          pagePaddRemove
        />
      </div>
      <div id="features" ref={featuresRef}>
        <Features
          title="Features"
          sections = {monitoringSection}
          tabListState = {monitoringListState}
        />
      </div>
      <StyledSectionWrapper>
        <GetStarted
          title="Integrations with External Services"
          desc="Export metrics and operations log to external services"
          btnContent="Start with Hasura Cloud"
          btnLink="https://cloud.hasura.io/signup?pg=graphql-monitoring&plcmt=body&cta=start-with-hasura-cloud&tech=default"
          externalService = {externalServicesBrand}

        />
      </StyledSectionWrapper>
    </>
  );
};

export default MonitoringIndex;
